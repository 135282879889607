<template>
  <custom-select
    v-bind="$attrs"
    v-on="$listeners"
    :options="maritalStatuses"
    :value="value"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      maritalStatuses: [
        { title: 'Single', value: 'single' },
        { title: 'Engaged', value: 'engaged' },
        { title: 'Married', value: 'married' },
        { title: 'Divorced', value: 'divorced' },
        { title: 'Widowed', value: 'widowed' },
      ],
    };
  },
};
</script>
